import VideoPlayIcon from "@/core/ui/iconsax/linear/videoPlayIcon.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannelAttachBlock from "@components/chat/channel/attachment/ChatChannelAttachBlock"
import { CustomChatChannelFileAttachment } from "@components/chat/channel/attachment/CustomChatChannelFileAttachment"
import { VALID_ATTACH_BLOCK_TYPES } from "@components/chat/channel/detail/message/ChatChannelMessage"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import { Attachment } from "stream-chat"
import { DefaultStreamChatGenerics } from "stream-chat-react"

type Props = {
  quotedMessageAttachment: Attachment<DefaultStreamChatGenerics>[]
}
export default function ChatChannelQuotedMessageAttachments(props: Props) {
  const classes = useStyles()

  const attachBlockAttachments = props.quotedMessageAttachment.filter((attachment) =>
    VALID_ATTACH_BLOCK_TYPES.includes(attachment.type as AttachBlockEntity)
  )
  const fileAttachments = props.quotedMessageAttachment.filter((attachment) =>
    ["file", "audio"].includes(attachment.type || "")
  )
  return (
    <>
      {props.quotedMessageAttachment.map((attachment) => (
        <>
          {/* image quoted previews */}
          {attachment.image_url && !attachment.og_scrape_url && (
            <div className={classes.mediaContainer}>
              <div className={classes.overlay} />
              <img
                src={attachment.image_url}
                alt={attachment.title}
                className={classes.thumbnail}
                data-testid={"quoted-message-attachment-image"}
              />
            </div>
          )}
          {/* video quoted previews */}
          {attachment.type === "video" && (
            <div className={classes.mediaContainer}>
              <div className={classes.overlay} />
              <video
                src={attachment.asset_url}
                className={classes.videoThumbnail}
                data-testid={"quoted-message-attachment-video"}
              />
              <VideoPlayIcon className={classes.playIcon} />
            </div>
          )}
        </>
      ))}
      {attachBlockAttachments.map((attachment) =>
        (() => {
          return (
            <div className={classes.mediaContainer}>
              <div className={classes.overlay} />
              <ChatChannelAttachBlock
                type={attachment.type as AttachBlockEntity}
                entityId={attachment.text!}
              />
            </div>
          )
        })()
      )}
      {fileAttachments.map((file) => {
        return (
          <div key={file.asset_url} className={classes.mediaContainer}>
            <div className={classes.overlay} />
            <div className={classes.item} data-testid={"quoted-file-attachment"}>
              <CustomChatChannelFileAttachment
                name={file.title}
                url={file.asset_url}
                size={file.file_size}
              />
            </div>
          </div>
        )
      })}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  thumbnail: {
    position: "relative",
    borderRadius: theme.measure.borderRadius.medium,
    border: "1px solid #E4E4E7",
    height: "80px",
    width: "80px",
    objectFit: "cover",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexShrink: 0,
  },
  videoThumbnail: {
    display: "flex",
    borderRadius: theme.measure.borderRadius.medium,
    height: "80px",
    width: "80px",
    objectFit: "cover",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  },
  mediaContainer: {
    position: "relative",
    borderRadius: theme.measure.borderRadius.medium,
    overflow: "hidden",
    flexShrink: 0,
  },
  playIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
  },
  overlay: {
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    backgroundColor:
      theme.palette.type === "dark"
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(255,255,255,0.5)",
    zIndex: 1,
    pointerEvents: "none",
    borderRadius: "inherit",
    boxSizing: "content-box !important",
  },
  item: {
    height: "80px",
    width: "400px",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.big,
    flexShrink: 0,
    position: "relative",
    border: "1px solid",
    boxShadow: theme.palette.groovyDepths.xs,
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    overflow: "hidden",
  },
}))
