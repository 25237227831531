import BulletIcon from "@/core/ui/iconsax/linear/custom-bulletpoints.svg"
import NumberedListIcon from "@/core/ui/iconsax/linear/numberedList.svg"
import BoldIcon from "@/core/ui/iconsax/linear/text-bold.svg"
import ItalicIcon from "@/core/ui/iconsax/linear/text-italic.svg"
import LinkIcon from "@/core/ui/iconsax/linear/text-link.svg"
import StrikethroughIcon from "@/core/ui/iconsax/linear/text-strikethrough.svg"
import { useTheme } from "@material-ui/core/styles"

import makeUseStyles from "@assets/style/util/makeUseStyles"

import {
  DiscoButton,
  DiscoIconButton,
  DiscoInput,
  DiscoModal,
  DiscoText,
} from "@disco-ui"
import { useCallback, useEffect, useState } from "react"
import { useMessageInputContext } from "stream-chat-react"

export function ChatChannelMessageMarkdownMenu() {
  const classes = useStyles()
  const theme = useTheme()
  const { text, setText, textareaRef: innerRef } = useMessageInputContext()
  const [linkModal, setLinkModal] = useState(false)
  const [linkText, setLinkText] = useState("")
  const [linkUrl, setLinkUrl] = useState("")
  const setCursorPosition = useCallback(
    (newPosition: number) => {
      const textArea = innerRef.current
      setTimeout(() => {
        if (textArea) {
          textArea.focus()
          textArea.selectionStart = newPosition
          textArea.selectionEnd = newPosition
        }
      }, 0)
    },
    [innerRef]
  )
  const handleMarkup = useCallback(
    (markdownType: string) => {
      const start = innerRef.current?.selectionStart ?? 0
      const end = innerRef.current?.selectionEnd ?? 0
      const selectedText = text.substring(start, end)
      const beforeSelection = text.substring(0, start)
      const afterSelection = text.substring(end)
      const lines = selectedText.split("\n")

      // Highlighted text
      if (markdownType === "bold" && start !== end) {
        const newText = `${beforeSelection}**${selectedText}**${afterSelection}`
        setText(newText)
        setCursorPosition(end + 4)
        return
      }
      if (markdownType === "strikethrough" && start !== end) {
        const newText = `${beforeSelection}~~${selectedText}~~${afterSelection}`
        setText(newText)
        setCursorPosition(end + 4)
        return
      }

      if (markdownType === "italic" && start !== end) {
        const newText = `${beforeSelection}*${selectedText}*${afterSelection}`
        setText(newText)
        setCursorPosition(end + 2)
        return
      }

      if (markdownType === "bulletPoint" && start !== end) {
        const bulletedLines = lines
          .map((line) => (line.trim() ? `- ${line}` : line))
          .join("\n")
        const newText = `${beforeSelection}${bulletedLines}${afterSelection}`
        const newPosition = beforeSelection.length + bulletedLines.length

        setText(newText)
        setCursorPosition(newPosition)
        return
      }

      if (markdownType === "numberedList" && start !== end) {
        const numberedLines = lines
          .map((line, index) => {
            return line.trim() ? `${index + 1}. ${line}` : line
          })
          .join("\n")
        const newText = `${beforeSelection}${numberedLines}${afterSelection}`
        const newPosition = beforeSelection.length + numberedLines.length

        setText(newText)
        setCursorPosition(newPosition)
        return
      }

      if (markdownType === "bold") {
        const newText = `${text.substring(0, start)}****${text.substring(end)}`
        setText(newText)
        setCursorPosition(start + 2)
      }
      if (markdownType === "italic") {
        const newText = `${text.substring(0, start)}**${text.substring(end)}`
        setText(newText)
        setCursorPosition(start + 1)
      }
      if (markdownType === "strikethrough") {
        const newText = `${text.substring(0, start)}~~~~${text.substring(end)}`
        setText(newText)
        setCursorPosition(start + 2)
      }
      if (markdownType === "bulletPoint") {
        let prefix = "- "
        if (!(start === 0 || text[start - 1] === "\n")) {
          prefix = "\n- "
        }
        const newText = `${beforeSelection}${prefix}${afterSelection}`
        const newPosition = beforeSelection.length + prefix.length
        setText(newText)
        setCursorPosition(newPosition)
      }
      if (markdownType === "numberedList") {
        let prefix = "1. "

        // If we're not at the start of a line, insert a newline first
        if (!(start === 0 || text[start - 1] === "\n")) {
          prefix = "\n1. "
        }

        const newText = `${beforeSelection}${prefix}${afterSelection}`
        const newPosition = beforeSelection.length + prefix.length

        setText(newText)
        setCursorPosition(newPosition)
      }
      if (markdownType === "link") {
        // in stream chat react, link only appears if link starts with "http://"
        const newLinkUrl =
          linkUrl.includes("https://") || linkUrl.includes("http://")
            ? linkUrl
            : `http://${linkUrl}`
        const markDown = `[${linkText}](${newLinkUrl})`
        setText(`${beforeSelection}${markDown}${afterSelection}`)
        const newPosition =
          beforeSelection.length + newLinkUrl.length + linkText.length + 4
        setCursorPosition(newPosition)
        setLinkText("")
        setLinkUrl("")
      }
    },
    [innerRef, linkText, linkUrl, setCursorPosition, setText, text]
  )
  const handleLinkModalSetUp = useCallback(() => {
    const start = innerRef.current?.selectionStart ?? 0
    const end = innerRef.current?.selectionEnd ?? 0
    const selectedText = text.substring(start, end)
    const [markdownLinkText, markdownLinkUrl] = extractMarkdownLinkParts(selectedText)
    if (markdownLinkText && markdownLinkUrl) {
      setLinkText(markdownLinkText)
      setLinkUrl(markdownLinkUrl)
    } else {
      setLinkText(selectedText)
    }
  }, [innerRef, text])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Only handle shortcuts if our specific textarea is focused
      if (document.activeElement !== innerRef.current) return

      const modifierKey = event.metaKey || event.ctrlKey
      if (event.key === "b" && modifierKey) {
        event.preventDefault()
        handleMarkup("bold")
      }
      if (event.key === "i" && modifierKey) {
        event.preventDefault()
        handleMarkup("italic")
      }
      if (event.key === "s" && modifierKey) {
        event.preventDefault()
        handleMarkup("strikethrough")
      }
      if (event.shiftKey && event.key === "u" && modifierKey) {
        event.preventDefault()
        setLinkModal(true)
        handleLinkModalSetUp()
      }
    }
    window.addEventListener("keydown", handleKeyPress)
    return () => window.removeEventListener("keydown", handleKeyPress)
  }, [handleMarkup, handleLinkModalSetUp, innerRef])

  return (
    <div className={classes.markdownMenu}>
      <DiscoIconButton
        onClick={() => handleMarkup("bold")}
        height={40}
        width={40}
        svgStyles={{ height: 20, width: 20 }}
        color={theme.palette.groovy.neutral[700]}
      >
        <BoldIcon />
      </DiscoIconButton>
      <DiscoIconButton
        onClick={() => handleMarkup("italic")}
        height={40}
        width={40}
        svgStyles={{ height: 20, width: 20 }}
        color={theme.palette.groovy.neutral[700]}
      >
        <ItalicIcon />
      </DiscoIconButton>
      <DiscoIconButton
        onClick={() => handleMarkup("strikethrough")}
        height={40}
        width={40}
        svgStyles={{ height: 20, width: 20 }}
        color={theme.palette.groovy.neutral[700]}
      >
        <StrikethroughIcon />
      </DiscoIconButton>
      <DiscoIconButton
        onClick={() => {
          setLinkModal(true)
          handleLinkModalSetUp()
        }}
        height={40}
        width={40}
        svgStyles={{ height: 20, width: 20 }}
        color={theme.palette.groovy.neutral[700]}
      >
        <LinkIcon />
      </DiscoIconButton>
      <div className={classes.divider} />

      <DiscoIconButton
        onClick={() => handleMarkup("bulletPoint")}
        height={40}
        width={40}
        svgStyles={{ height: 20, width: 20 }}
        color={theme.palette.groovy.neutral[700]}
      >
        <BulletIcon />
      </DiscoIconButton>
      <DiscoIconButton
        onClick={() => handleMarkup("numberedList")}
        height={40}
        width={40}
        svgStyles={{ height: 20, width: 20 }}
        color={theme.palette.groovy.neutral[700]}
      >
        <NumberedListIcon />
      </DiscoIconButton>

      {linkModal && (
        <DiscoModal
          isOpen
          onClose={() => setLinkModal(false)}
          testid={"linkModal"}
          modalContentLabel={"linkModal"}
          title={"Add link"}
          body={
            <div className={classes.linkModalBody}>
              <DiscoText variant={"body-md"}>{"Text"}</DiscoText>
              <DiscoInput
                value={linkText}
                onChange={(e) => setLinkText(e.target.value)}
                placeholder={"Check this out!"}
              />
              <DiscoText variant={"body-md"}>{"Link"}</DiscoText>
              <DiscoInput
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
                placeholder={"Paste link here"}
              />
              <div className={classes.linkModalButtons}>
                <DiscoButton color={"grey"} onClick={() => setLinkModal(false)}>
                  {"Cancel"}
                </DiscoButton>
                <DiscoButton
                  disabled={!linkText.trim() || !linkUrl.trim()}
                  onClick={() => {
                    handleMarkup("link")
                    setLinkModal(false)
                  }}
                >
                  {"Save"}
                </DiscoButton>
              </div>
            </div>
          }
        />
      )}
    </div>
  )

  function extractMarkdownLinkParts(
    markdownLink: string
  ): [string | null, string | null] {
    const pattern = /\[([^\]]+)\]\(([^)]+)\)/
    const match = markdownLink.match(pattern)
    if (match) {
      return [match[1], match[2]]
    }
    return [null, null]
  }
}

const useStyles = makeUseStyles((theme) => ({
  markdownMenu: {
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    backgroundColor: theme.palette.groovy.neutral[100],
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderRadius: `${theme.measure.borderRadius.big}px ${theme.measure.borderRadius.big}px 0 0`,
  },
  linkModalBody: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  linkModalButtons: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
  },
  divider: {
    height: "40px",
    borderLeft: `1.5px solid ${theme.palette.divider}`,
  },
}))
