import { useBotThreadSuggestion } from "@/chat/channel/BotSuggestionsContext"
import { useDrawerContext } from "@/core/context/DrawerContext"
import { StreamChatUserData } from "@/core/context/StreamChatContext"
import VideoPlayIcon from "@/core/ui/iconsax/linear/videoPlayIcon.svg"

import LeftIcon from "@/core/ui/iconsax/linear/arrow-left-1.svg"
import RightIcon from "@/core/ui/iconsax/linear/arrow-right-1.svg"

import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import OpenProfilePopoverButton from "@/user/common/profile-popover/OpenProfilePopoverButton"
import styleIf from "@assets/style/util/styleIf"
import { ChatChannelLinkPreviewer } from "@components/chat/channel/attachment/ChatChannelLinkPreviewer"
import { CustomChatChannelFileAttachment } from "@components/chat/channel/attachment/CustomChatChannelFileAttachment"
import { ChatChannelMessageFragment$key } from "@components/chat/channel/detail/message/__generated__/ChatChannelMessageFragment.graphql"
import ChatChannelDeletedMessage from "@components/chat/channel/detail/message/ChatChannelDeletedMessage"
import { MessageText } from "@components/chat/channel/detail/message/ChatChannelMessageText"
import ChatChannelBotSuggestedMessage from "@components/chat/channel/detail/message/suggestion/ChatChannelBotSuggestedMessage"
import ChatChannelMessageOptions from "@components/chat/channel/message-options/ChatChannelMessageOptions"
import ChatChannelReactionList from "@components/chat/channel/reaction-list/ChatChannelReactionList"
import { cleanPreviewAttachments } from "@components/chat/util/chatUtils"
import {
  ATTACH_BLOCK_CONFIG,
  AttachBlockEntity,
} from "@components/editor/plugins/attach-block/AttachBlockNode"
import {
  DiscoIcon,
  DiscoIconButton,
  DiscoModal,
  DiscoText,
  DiscoTextButton,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import classNames from "classnames"
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { Attachment } from "stream-chat"
import {
  EditMessageForm as DefaultEditMessageForm,
  MessageRepliesCountButton as DefaultMessageRepliesCountButton,
  MessageTimestamp as DefaultMessageTimestamp,
  DefaultStreamChatGenerics,
  MessageContextValue,
  MessageInput,
  MessageUIComponentProps,
  useChatContext,
  useComponentContext,
  useMessageContext,
} from "stream-chat-react"

export const VALID_ATTACH_BLOCK_TYPES: AttachBlockEntity[] = [
  "occurrence",
  "contentUsage",
  "product",
  "survey",
]

interface MessageWithContextProps extends MessageContextValue {
  /** Whether or not to disable stuff like reacting/reply in thread when you hover over the message */
  disableOptions?: boolean
  chatChannelKey: ChatChannelMessageFragment$key
}

function MessageWithContext(props: MessageWithContextProps) {
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    handleOpenThread,
    handleRetry,
    message,
    threadList,
    firstOfGroup,
    chatChannelKey,
  } = props

  const {
    EditMessageInput = DefaultEditMessageForm,
    MessageRepliesCountButton = DefaultMessageRepliesCountButton,
    MessageTimestamp = DefaultMessageTimestamp,
  } = useComponentContext()
  const isFailedMessage = message.status === "failed"
  const isPinnedMessage = Boolean(message.pinned)
  const { isInDrawer, closeDrawer } = useDrawerContext()
  const [showOptions, setShowOptions] = useState(false)
  const [messageAttachments, setMessageAttachments] = useState(message.attachments)
  const [mediaAttachment, setMediaAttachment] = useState<
    Attachment<DefaultStreamChatGenerics> | undefined
  >(undefined)
  const [mediaModal, setMediaModal] = useState(false)
  // append all videos and images into an array
  const imageAndVideoPreviews = messageAttachments?.filter(
    (attachment) =>
      (attachment.type === "video" || attachment.type === "image") &&
      !attachment.og_scrape_url
  )
  const uniqueLinkSet = new Set<string>()
  const uniqueLinks = messageAttachments
    ?.filter((attachment) => attachment.title_link)
    .filter((attachment) => {
      const normalizedLink = attachment.title_link?.replace(/\/$/, "") ?? ""
      if (
        uniqueLinkSet.has(normalizedLink ?? "") ||
        uniqueLinkSet.has(`${normalizedLink}/`)
      ) {
        return false
      }
      uniqueLinkSet.add(attachment.title_link ?? "")
      return true
    })

  const chatChannel = useFragment<ChatChannelMessageFragment$key>(
    graphql`
      fragment ChatChannelMessageFragment on ChatChannel {
        id
        ...ChatChannelMessageOptionsFragment
      }
    `,
    chatChannelKey
  )
  const handleGalleryScrolling = useCallback(
    (direction: string) => {
      if (direction === "left" && imageAndVideoPreviews) {
        setMediaAttachment(
          imageAndVideoPreviews[
            (imageAndVideoPreviews.findIndex(
              (attachment) => attachment === mediaAttachment
            ) -
              1 +
              imageAndVideoPreviews.length) %
              imageAndVideoPreviews.length
          ]
        )
      } else if (direction === "right" && imageAndVideoPreviews) {
        setMediaAttachment(
          imageAndVideoPreviews[
            (imageAndVideoPreviews.findIndex(
              (attachment) => attachment === mediaAttachment
            ) +
              1) %
              imageAndVideoPreviews.length
          ]
        )
      }
    },
    [imageAndVideoPreviews, mediaAttachment]
  )

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        handleGalleryScrolling("left")
      } else if (event.key === "ArrowRight") {
        handleGalleryScrolling("right")
      }
    }

    if (mediaModal === true) {
      document.addEventListener("keydown", handleKeyPress)
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [handleGalleryScrolling, mediaModal])

  const classes = useStyles({ isFailedMessage, isPinnedMessage, isInDrawer })
  const theme = useTheme()

  const divRef = useRef<HTMLDivElement | null>(null)
  const { client } = useChatContext()

  const { botSuggestion } = useBotThreadSuggestion({
    threadId: message.id,
    // Only get suggestions for main thread messages
    disabled: Boolean(message.parent_id),
  })
  useEffect(() => {
    const cleanedAttachments = cleanPreviewAttachments(message.attachments)
    setMessageAttachments(cleanedAttachments)
  }, [message.attachments])

  if (message.deleted_at || message.type === "deleted") {
    return (
      <ChatChannelDeletedMessage
        message={message}
        threadList={threadList}
        handleReply={handleClickReply}
      />
    )
  }

  const messageUser = message.user as StreamChatUserData | undefined

  return (
    <>
      <div
        key={message.id}
        className={classes.container}
        data-testid={"ChatChannelSimpleMessage.container"}
        onClick={handleRetryMessage}
        onMouseEnter={handleShowOptions}
        onMouseLeave={handleHideOptions}
        onKeyDown={handleRetryMessage}
        role={"button"}
        tabIndex={0}
      >
        {/* Pinned Message */}
        {isPinnedMessage && (
          <div className={classes.pinnedContainer}>
            <DiscoIcon
              icon={"pin"}
              active
              color={theme.palette.primary.main}
              paddingRight={1}
              height={24}
              width={24}
            />
            <DiscoText variant={"body-sm-600"} color={"primary.main"}>
              {message.pinned_by ? `Pinned by ${message.pinned_by.name}` : "Pinned"}
            </DiscoText>
          </div>
        )}

        {!editing && (
          <div ref={divRef} className={classes.messageContainer}>
            {messageUser && (
              <>
                {firstOfGroup ? (
                  <OpenProfilePopoverButton
                    testid={"ChatChannelMessageAvatar"}
                    className={classes.baseButton}
                    userId={Relay.toGlobalId("User", messageUser.disco_user_id)}
                  >
                    <UserAvatar
                      placeholderClassName={"avatar-placeholder"}
                      user={{
                        id: Relay.toGlobalId("User", messageUser.disco_user_id),
                        first_name: messageUser.first_name,
                        last_name: messageUser.last_name,
                        avatar: messageUser.avatar,
                        is_test_user: messageUser.is_disco_test_user || false,
                      }}
                      size={40}
                    />
                  </OpenProfilePopoverButton>
                ) : (
                  <div className={classes.profilePlaceholder} />
                )}
              </>
            )}
            <div data-testid={"message-inner"} className={classes.message}>
              {/* User */}
              <div className={classNames(classes.nameAndDate, "message__name-and-date")}>
                {message.user?.name ? (
                  <OpenProfilePopoverButton
                    testid={"ChatChannelMessageUserName"}
                    customAnchor={divRef.current}
                    userId={Relay.toGlobalId(
                      "User",
                      message.user.disco_user_id as string
                    )}
                  >
                    {(buttonProps) => (
                      <DiscoTextButton {...buttonProps}>
                        <DiscoText
                          variant={"body-md-600"}
                          display={"inline"}
                          marginRight={1}
                        >
                          {message?.user?.name && message.user.name}
                        </DiscoText>
                      </DiscoTextButton>
                    )}
                  </OpenProfilePopoverButton>
                ) : null}

                <MessageTimestamp
                  format={DATE_FORMAT.SHORT_TIME_FORMAT}
                  customClass={classes.timestamp}
                />
              </div>

              {/* Options */}
              {!props.disableOptions && showOptions && (
                <ChatChannelMessageOptions chatChannelKey={chatChannel} />
              )}

              {/* Message */}
              <MessageText message={message} customInnerClass={classes.messageText} />

              {/* Link previews */}
              {uniqueLinks?.map((attachment) => (
                <ChatChannelLinkPreviewer
                  key={attachment.id}
                  attachment={attachment}
                  client={client}
                  message={message}
                />
              ))}

              {/* Attachments */}
              {messageAttachments?.length ? (
                <>
                  {/* images */}
                  {imageAndVideoPreviews?.length === 1 &&
                  imageAndVideoPreviews[0].type === "video" ? (
                    <video
                      src={imageAndVideoPreviews[0].asset_url}
                      title={imageAndVideoPreviews[0].title}
                      style={{ border: 0, width: 440, height: 300, borderRadius: 12 }}
                      controls
                    />
                  ) : (
                    <div className={classes.imageContainer}>
                      {imageAndVideoPreviews?.map((attachment) => (
                        <>
                          {/* render image and video thumbnails */}
                          {attachment.type === "image" && (
                            <span
                              onClick={() => {
                                setMediaAttachment(attachment)
                                setMediaModal(true)
                              }}
                              role={"button"}
                              tabIndex={0}
                              onKeyPress={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  setMediaModal(true)
                                }
                              }}
                            >
                              <img
                                key={attachment.id}
                                src={attachment.image_url}
                                alt={attachment.title}
                                className={classes.imageThumbnail}
                              />
                            </span>
                          )}
                          {attachment.type === "video" && (
                            <div className={classes.video}>
                              <span
                                onClick={() => {
                                  setMediaAttachment(attachment)
                                  setMediaModal(true)
                                }}
                                role={"button"}
                                tabIndex={0}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter" || e.key === " ") {
                                    setMediaModal(true)
                                  }
                                }}
                                className={classes.videoContainer}
                              >
                                <div className={classes.overlay} />

                                <video
                                  src={attachment.asset_url}
                                  className={classes.thumbnail}
                                  onClick={() => {
                                    setMediaModal(true)
                                  }}
                                />
                                <VideoPlayIcon className={classes.playIcon} />
                              </span>
                            </div>
                          )}
                        </>
                      ))}
                      {mediaModal && (
                        <DiscoModal
                          isOpen
                          onClose={() => setMediaModal(false)}
                          testid={"photoPreviewModal"}
                          title={mediaAttachment?.fallback || mediaAttachment?.title}
                          modalContentLabel={"photoPreviewModal"}
                          body={
                            <>
                              {(imageAndVideoPreviews?.length ?? 0) > 1 && (
                                <>
                                  <DiscoIconButton
                                    onClick={() => {
                                      handleGalleryScrolling("left")
                                    }}
                                    height={40}
                                    width={40}
                                    svgStyles={{ height: 20, width: 20 }}
                                    className={classes.modalLeftArrow}
                                  >
                                    <LeftIcon />
                                  </DiscoIconButton>

                                  <DiscoIconButton
                                    onClick={() => {
                                      handleGalleryScrolling("right")
                                    }}
                                    height={40}
                                    width={40}
                                    svgStyles={{ height: 20, width: 20 }}
                                    classes={{
                                      root: classNames(classes.modalRightArrow),
                                    }}
                                  >
                                    <RightIcon />
                                  </DiscoIconButton>
                                </>
                              )}
                              {mediaAttachment?.type === "image" ? (
                                <img
                                  key={mediaAttachment?.id}
                                  src={mediaAttachment?.image_url}
                                  alt={mediaAttachment?.title}
                                  className={classes.image}
                                />
                              ) : (
                                <video
                                  src={mediaAttachment?.asset_url}
                                  controls
                                  className={classes.modalVideo}
                                />
                              )}
                            </>
                          }
                          classes={{
                            container: classes.mediaContainer,
                            body: classes.mediaBody,
                          }}
                        />
                      )}
                    </div>
                  )}

                  {messageAttachments.some(
                    (attachment) =>
                      attachment.type === "file" ||
                      attachment.type === "occurrence" ||
                      attachment.type === "contentUsage" ||
                      attachment.type === "product" ||
                      attachment.type === "survey" ||
                      attachment.type === "pathway" ||
                      attachment.type === "audio"
                  ) && (
                    <>
                      {/* files and attachments*/}
                      <div className={classes.fileContainer}>
                        {/* files and audio*/}
                        {messageAttachments
                          .filter(
                            (attachment) =>
                              (attachment.type === "file" ||
                                attachment.type === "audio") &&
                              !attachment.og_scrape_url
                          )
                          .map((attachment) => (
                            <div key={attachment.id} className={classes.file}>
                              <CustomChatChannelFileAttachment
                                name={attachment.title}
                                url={attachment.asset_url}
                                size={attachment.file_size}
                              />
                            </div>
                          ))}
                        {/* events, products, lessons, surveys */}
                        {messageAttachments
                          .filter(
                            (attachment) =>
                              attachment.type &&
                              VALID_ATTACH_BLOCK_TYPES.includes(
                                attachment.type as AttachBlockEntity
                              )
                          )
                          .map((attachment) =>
                            (() => {
                              const config =
                                ATTACH_BLOCK_CONFIG[attachment.type as AttachBlockEntity]
                              const entityId = attachment.text!
                              const component = React.createElement(config.Component, {
                                entityId,
                              })
                              return <Fragment key={entityId}>{component}</Fragment>
                            })()
                          )}
                      </div>
                    </>
                  )}
                </>
              ) : null}

              {/* Reactions */}
              <ChatChannelReactionList />

              {/* Replies */}
              {!threadList && !!message.reply_count && (
                <div className={classes.replyContainer}>
                  <MessageRepliesCountButton
                    onClick={(e) => handleClickReply(e)}
                    reply_count={message.reply_count}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {botSuggestion && !editing && (
        <ChatChannelBotSuggestedMessage
          botMessageKey={botSuggestion}
          inThread={threadList}
          threadId={message.id}
        />
      )}
      {editing && (
        <MessageInput
          clearEditingState={clearEditingState}
          Input={EditMessageInput}
          message={message}
          {...additionalMessageInputProps}
        />
      )}
    </>
  )

  function handleShowOptions() {
    setShowOptions(true)
  }

  function handleHideOptions() {
    setShowOptions(false)
  }

  function handleRetryMessage() {
    if (isFailedMessage) {
      handleRetry(message)
    }
  }

  function handleClickReply(event: React.MouseEvent<Element, MouseEvent>) {
    if (closeDrawer) closeDrawer()
    handleOpenThread(event)
  }
}

type StyleProps = {
  isFailedMessage: boolean
  isPinnedMessage: boolean
  isInDrawer: boolean
}

const useStyles = makeUseStyles((theme) => ({
  baseButton: {
    padding: "0px",
  },
  container: ({ isFailedMessage, isPinnedMessage, isInDrawer }: StyleProps) => ({
    display: "flex",
    outline: "none",
    borderRadius: theme.measure.borderRadius.big,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[100],
    },
    ...styleIf(isPinnedMessage, {
      backgroundColor: isInDrawer
        ? theme.palette.background.paper
        : theme.palette.type === "dark"
        ? theme.palette.groovy.grey[700]
        : theme.palette.primary.light,
      borderRadius: theme.measure.borderRadius.big,
      flexDirection: "column",
      marginBottom: isInDrawer ? theme.spacing(1) : `${theme.spacing(1)}px !important`,
    }),

    cursor: isFailedMessage ? "pointer" : "default",

    "& .str-chat__avatar": {
      width: "40px",
      height: "40px",
      flex: "0 0 40px",
      marginRight: theme.spacing(2),

      ...styleIf(isPinnedMessage, {
        visibility: "visible",
        height: "40px !important",
      }),
    },

    "& .str-chat__message-mention": {
      color: theme.palette.primary.main,
      fontSize: "15px",
    },
    "& .str-chat__message-text-inner": {
      color: theme.palette.groovy.grey[700],
      padding: 0,
      borderRadius: 0,
      border: "none",
      backgroundColor: "transparent",
      minHeight: "auto",

      "& p": {
        ...theme.typography["body-sm"],
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "none",
      },
    },
    "& .str-chat__message-attachment--img": {
      borderRadius: theme.measure.borderRadius.medium,
      border: `1px solid ${theme.palette.groovy.grey[200]}`,
      height: "80px",
      width: "80px",
      objectFit: "cover",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      marginRight: theme.spacing(1.5),
    },
    "& .str-chat__message-attachment": {
      maxWidth: "100%",
    },
    "& .str-chat__gallery": {
      justifyContent: "flex-start",
      maxWidth: "100%",
      flexWrap: "wrap",
      gap: theme.spacing(0.5),

      "&--square": {
        backgroundColor: "transparent",
      },
    },
    "& .str-chat__message-url-link": {
      whiteSpace: "unset",
      wordBreak: "break-all",
    },
    "& .str-chat_player-wrapper": {
      height: "80px",
      width: "80px",
      paddingTop: 0,
    },
    "& .str-chat__message-attachment--card--no-image": {
      height: "unset",
    },
    "& .str-chat__message-attachment-card": {
      marginTop: 0,
      width: "fit-content",
      "&--url": {
        textTransform: "unset",
      },
      "&--header": {
        height: "unset",
        width: "unset",
        maxHeight: "175px",
        "& img": {
          width: "auto",
          height: "auto",
          margin: "auto",
        },
      },
      [theme.breakpoints.up("sm")]: {
        "&--image": {
          display: "flex",
          alignItems: "center",
        },
      },
    },
  }),
  image: {
    height: "100%",
    width: "100%",
    minWidth: "0",
    objectFit: "contain",
  },
  modalVideo: {
    height: "100%",
    width: "100%",
    minWidth: "0",
    objectFit: "contain",
  },
  modalRightArrow: {
    border: "1px solid",
    height: "40px",
    width: "40px",
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    top: "50%",
    right: "36px",
    transform: "translateY(-50%)",
  },
  modalLeftArrow: {
    border: "1px solid",
    height: "40px",
    width: "40px",
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    top: "50%",
    left: "36px",
    transform: "translateY(-50%)",
  },
  mediaBody: {
    height: "100%",
    minWidth: 0,
  },

  mediaContainer: {
    width: theme.measure.modalMaxWidth.large,
    height: "100%",
    position: "relative",
  },
  replyContainer: {
    marginBottom: theme.spacing(1),
  },
  nameAndDate: ({ isPinnedMessage }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: theme.spacing(1),

    ...styleIf(isPinnedMessage, {
      display: "flex !important",
    }),
  }),
  timestamp: {
    ...theme.typography["body-sm"],
    fontSize: "12px",
    color: theme.palette.groovy.grey[400],
    textTransform: "uppercase",
  },
  message: {
    minWidth: "90%",
    maxWidth: "100%",
    marginLeft: theme.spacing(1.5),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  messageContainer: {
    display: "flex",
    maxWidth: "100%",
    width: "100%",
    position: "relative",
    "& .str-chat__message-attachment": {
      maxHeight: "unset",

      "& .str-chat__message-attachment-card": {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",

        "& .str-chat__message-attachment-card--header img": {
          maxWidth: "100%",
        },
      },
    },
  },

  pinnedContainer: {
    display: "flex",
    padding: theme.spacing(0.5, 0, 1, 0),
    alignItems: "center",
  },
  messageText: {
    "& ul": {
      marginLeft: theme.spacing(2),
    },
    wordBreak: "normal",
    overflowWrap: "anywhere",
    // HTML code tags are wrapped in a <pre></pre> tag which must enforce word wrapping to avoid overflow
    "& > * > pre": {
      whiteSpace: "pre-wrap",
    },
  },
  file: {
    height: "80px",
    width: "400px",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.big,
    border: `1px solid`,
    flexShrink: 0,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.xs,
    borderColor: theme.palette.divider,
    padding: theme.spacing(2),
  },
  imageThumbnail: {
    borderRadius: theme.measure.borderRadius.medium,
    border: `1px solid ${theme.palette.groovy.neutral[200]}`,
    height: "80px",
    width: "80px",
    objectFit: "cover",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
  },
  fileContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },

  imageContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  profilePlaceholder: {
    width: "40px",
    height: "0px",
    flexShrink: 0,
  },
  video: {
    display: "flex",
    height: "80px",
    width: "80px",
    position: "relative",
    cursor: "pointer",
  },
  thumbnail: {
    borderRadius: theme.measure.borderRadius.big,
    height: "100%",
    width: "100%",
    objectFit: "cover",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  playIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 2,
    transform: "translateY(-50%) translateX(-50%)",
  },
  videoContainer: {
    position: "relative",
  },
  overlay: {
    position: "absolute",
    borderRadius: theme.measure.borderRadius.medium,
    inset: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: 1,
    pointerEvents: "none",
  },
}))

const MemoizedMessage = React.memo(MessageWithContext, (prevProps, nextProps) => {
  return prevProps.threadList !== nextProps.threadList
}) as typeof MessageWithContext

interface ChatChannelMessageProps extends MessageUIComponentProps {
  disableOptions?: MessageWithContextProps["disableOptions"]
  chatChannelKey: ChatChannelMessageFragment$key
}

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
function ChatChannelMessage(props: ChatChannelMessageProps) {
  const messageContext = useMessageContext()

  return <MemoizedMessage {...messageContext} {...props} />
}

export default ChatChannelMessage
