import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ChatChannelEmojiPickerDropdown from "@components/chat/channel/emoji-picker-dropdown/ChatChannelEmojiPickerDropdown"
import MessageAttachContentBlockButton from "@components/chat/channel/message-input/MessageAttachContentBlockButton"
import { displayWarningToast } from "@components/toast/ToastProvider"
import { DiscoIcon, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core/styles"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { FileUploadButton } from "react-file-utils"
import { useChannelStateContext, useMessageInputContext } from "stream-chat-react"

export function ChatChannelMessageInputActions() {
  const classes = useStyles()
  const theme = useTheme()
  const {
    setText,
    attachments,
    text,
    isUploadEnabled,
    cooldownRemaining,
    insertText,
    uploadNewFiles,
  } = useMessageInputContext()
  const { acceptedFiles, multipleUploads } = useChannelStateContext()
  const isMobile = useIsMobile()

  return (
    <>
      {/* Actions */}
      <div className={classes.inputActions}>
        <MessageAttachContentBlockButton
          setText={setText}
          attachments={attachments}
          text={text}
          classes={classes.iconButton}
        />
        {isUploadEnabled && !cooldownRemaining && (
          <DiscoTooltip content={"Attach Files"}>
            <div data-testid={"fileinput"} className={classes.iconButton}>
              <FileUploadButton
                accepts={acceptedFiles}
                handleFiles={handleFileUpload}
                multiple={multipleUploads}
              >
                <DiscoIcon
                  icon={"iconsax.direct-send"}
                  color={theme.palette.groovy.neutral[700]}
                />
              </FileUploadButton>
            </div>
          </DiscoTooltip>
        )}
        {/* Emoji Picker */}
        {!isMobile && (
          <ChatChannelEmojiPickerDropdown
            classes={classes.iconButton}
            onEmojiSelect={insertText}
            useNativeEmoji
          />
        )}

        <div className={classes.divider} />

        {isUploadEnabled && !cooldownRemaining && (
          <>
            <DiscoTooltip content={"Attach Images"}>
              <div data-testid={"fileinput"} className={classes.iconButton}>
                <FileUploadButton
                  accepts={["image/png", "image/jpeg", "image/gif"]}
                  handleFiles={handleFileUpload}
                  multiple={multipleUploads}
                >
                  <DiscoIcon icon={"image"} />
                </FileUploadButton>
              </div>
            </DiscoTooltip>
            <DiscoTooltip content={"Attach Videos"}>
              <div data-testid={"fileinput"} className={classes.iconButton}>
                <FileUploadButton
                  accepts={["video/mp4", "video/mov", "video/quicktime"]}
                  handleFiles={handleFileUpload}
                  multiple={multipleUploads}
                >
                  <DiscoIcon icon={"video"} />
                </FileUploadButton>
              </div>
            </DiscoTooltip>
          </>
        )}
      </div>
    </>
  )

  function handleFileUpload(files: FileList | File[]) {
    // maximum 10 attachments per message
    if (attachments.length + files.length <= 10) {
      uploadNewFiles(files)
    } else {
      displayWarningToast({
        message: "You can only upload 10 attachments at a time.",
        testid: `overflow-attachments-toast`,
      })
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  iconButton: {
    "& svg": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.groovy.grey[100]
          : theme.palette.groovy.grey[500],
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  inputActions: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    alignSelf: "flex-end",
    zIndex: theme.zIndex.raise2,
    flexShrink: 0,
    gap: theme.spacing(0.5),
    "& [data-testid='fileinput']": {
      "& .rfu-file-upload-button": {
        "& label": {
          margin: 0,
          padding: 0,
          cursor: "pointer",
          "& input": {
            display: "none",
          },
          "& svg": {
            fill: "transparent",
            color:
              theme.palette.type === "dark"
                ? theme.palette.groovy.grey[100]
                : theme.palette.groovy.grey[500],
            opacity: 1,
            width: "20px",
            height: "20px",
          },
        },
      },
    },
    "& label": {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      padding: "3px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& span": {
        display: "none",
      },
    },
  },
  divider: {
    height: "30px",
    borderLeft: `1.5px solid ${theme.palette.divider}`,
  },
}))
