import makeUseStyles from "@assets/style/util/makeUseStyles"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import AttachBlockSelectModal from "@components/editor/plugins/attach-block/AttachBlockSelectModal"
import { displayWarningToast } from "@components/toast/ToastProvider"
import { DiscoDropdown, DiscoIcon, DiscoTooltip } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { IconButton } from "@material-ui/core"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import classNames from "classnames"
import React, { Suspense, useCallback, useRef, useState } from "react"
import { Attachment } from "stream-chat"
import { useMessageInputContext } from "stream-chat-react"
import { v4 as uuidv4 } from "uuid"

type Props = {
  setText: (text: string) => void
  attachments: Attachment[]
  text: string
  classes?: string
}

const MessageAttachContentBlockButton: React.FC<Props> = ({
  setText,
  attachments,
  text,
  classes: propClasses,
}) => {
  const testId = `MessageActions`
  const [showAttachBlock, setShowAttachBlock] = useState(false)

  const buttonRef = useRef<HTMLDivElement>(null)

  const [attachBlockEntity, setAttachBlockEntity] = useState<AttachBlockEntity>("product")

  const classes = useStyles()
  const { surveys } = useFeatureFlags()
  const messageInput = useMessageInputContext()

  const MessageActionAttachItems = [
    <DiscoDropdownItem
      key={`${testId}.AttachEvent`}
      title={"Attach an Event"}
      icon={<DiscoIcon icon={"calendar"} />}
      testid={`${testId}.AttachEvent`}
      onClick={() => handleClick("occurrence")}
    />,
    <DiscoDropdownItem
      key={`${testId}.AttachContent`}
      title={"Attach Content"}
      icon={<DiscoIcon icon={"book-open"} />}
      testid={`${testId}.AttachContent`}
      onClick={() => handleClick("contentUsage")}
    />,
    <DiscoDropdownItem
      key={`${testId}.AttachProduct`}
      title={"Attach a Product"}
      icon={<DiscoIcon icon={"3d-box"} />}
      testid={`${testId}.AttachProduct`}
      onClick={() => handleClick("product")}
    />,
  ]
  if (surveys)
    MessageActionAttachItems.push(
      <DiscoDropdownItem
        key={`${testId}.AttachSurvey`}
        title={"Attach a Survey"}
        icon={<DiscoIcon icon={"file-check"} />}
        testid={`${testId}.AttachSurvey`}
        onClick={() => handleClick("survey")}
      />
    )

  return (
    <div ref={buttonRef} className={classes.container}>
      <DiscoDropdown
        vertical={0}
        menuButton={useCallback(
          ({ onClick }) => {
            const icon = (
              <IconButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  onClick(e)
                  e.preventDefault()
                  e.stopPropagation()
                }}
                className={classNames(classes.iconButton, propClasses)}
                size={"small"}
              >
                <DiscoIcon width={20} height={20} icon={"add-circle"} />
              </IconButton>
            )
            return (
              <DiscoTooltip content={"Attach"}>
                <div>{icon}</div>
              </DiscoTooltip>
            )
          },
          [classes.iconButton, propClasses]
        )}
      >
        {MessageActionAttachItems}
      </DiscoDropdown>
      <Suspense fallback={null}>
        <AttachBlockSelectModal
          isOpen={showAttachBlock}
          onClose={handleClose}
          anchorElem={buttonRef.current}
          attachBlockEntity={attachBlockEntity}
          getAttachBlockEntityId={getAttachBlockEntityId}
          inline={false}
        />
      </Suspense>
    </div>
  )

  function getAttachBlockEntityId(entity: AttachBlockEntity, entityId: string) {
    attachments?.push({
      text: entityId,
      type: entity,
      localMetadata: {
        id: uuidv4(),
      },
    })
    // Update input text to refresh attachments list
    const currentText = text
    setText(`${currentText} `)
    setTimeout(() => setText(currentText), 20)
  }
  function handleClick(entity: AttachBlockEntity) {
    // maximum 10 attachments per message
    if (messageInput.attachments.length < 10) {
      setAttachBlockEntity(entity)
      setShowAttachBlock(true)
    } else {
      displayWarningToast({
        message: "You can only upload 10 attachments at a time.",
        testid: `overflow-attachments-toast`,
      })
    }
  }
  function handleClose() {
    setShowAttachBlock(false)
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: theme.palette.groovy.grey[300],
  },
}))

export default React.memo(MessageAttachContentBlockButton)
