import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { ChatChannelRemoveAttachmentButton } from "@components/chat/channel/uploads/ChatChannelRemoveAttachmentButton"
import classNames from "classnames"
import { LoadingIndicator } from "stream-chat-react"

export type ImagePreviewerProps = {
  /** The list of image uploads that should be displayed */
  imageUploads?: {
    id?: string
    localMetadata?: {
      id: string
      file?: File
      uploadState?: string
    }
    type?: string
    image_url?: string
    title?: string
    uploadState?: string
  }[]
  /** A callback to call when the remove icon is clicked */
  handleRemove?: (id: string) => void
  /** A callback to call when the retry icon is clicked */
  handleRetry?: (id: string) => void
  /** A callback to call with newly selected files. If this is not provided no
   * `ThumbnailPlaceholder` will be displayed.
   */
  handleFiles?: (files: File[]) => void
  /** Allow drag 'n' drop (or selection from the file dialog) of multiple files */
  multiple?: boolean
  disabled?: boolean
}

/** Display a list of uploaded images
 * Based off the `react-file-utils` component
 * ref: https://github.com/GetStream/react-file-utils/blob/develop/src/components/ImagePreviewer.tsx
 */
export const ChatChannelImagePreviewer = ({
  imageUploads,
  handleRemove,
}: Partial<ImagePreviewerProps>) => {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()

  return (
    <div className={classes.list}>
      {imageUploads?.map((image) => {
        const id = image.localMetadata?.id || image.id

        if (!id) return null

        return (
          <div
            key={id}
            className={classNames(showOnHoverClasses.hoverable, classes.image)}
          >
            {/* Thumbnail */}
            {image.image_url && image.localMetadata?.uploadState === "finished" && (
              <img
                src={image.image_url}
                alt={image.title}
                className={classes.thumbnail}
              />
            )}

            {/* Uploading */}
            {image.localMetadata?.uploadState !== "finished" && (
              <div className={classes.thumbnail}>
                <LoadingIndicator />
              </div>
            )}

            {/* Remove Button */}
            {handleRemove && (
              <ChatChannelRemoveAttachmentButton id={id} handleRemove={handleRemove} />
            )}
          </div>
        )
      })}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  list: {
    display: "flex",
    gap: theme.spacing(2),
  },
  image: {
    display: "flex",
    position: "relative",
  },
  thumbnail: {
    borderRadius: theme.measure.borderRadius.medium,
    border: ` 1px solid ${theme.palette.groovy.neutral[200]}`,
    height: "80px",
    width: "80px",
    objectFit: "cover",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
}))
