import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { MessageText } from "@components/chat/channel/detail/message/ChatChannelMessageText"
import ChatChannelQuotedMessageAttachments from "@components/chat/channel/detail/quoted-message/ChatChannelQuotedMessageAttachments"
import { DiscoText } from "@disco-ui"
import { IconButton, useTheme } from "@material-ui/core"
import { QuotedMessagePreviewProps, useChannelActionContext } from "stream-chat-react"

const QuotedMessagePreviewHeader = () => {
  const { setQuotedMessage } = useChannelActionContext()
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.header}>
      <DiscoText variant={"body-md"} color={"text.secondary"}>
        {"Reply to message:"}
      </DiscoText>
      <IconButton size={"small"} onClick={() => setQuotedMessage(undefined)}>
        <CloseIcon width={15} height={15} color={theme.palette.text.secondary} />
      </IconButton>
    </div>
  )
}

const ChatChannelQuotedMessagePreview: React.FC<QuotedMessagePreviewProps> = (props) => {
  const { quotedMessage } = props
  const classes = useStyles()

  const quotedMessageAttachment = quotedMessage.attachments?.length
    ? quotedMessage.attachments
    : null

  const quotedMessageAttachmentWithoutLinks = quotedMessageAttachment?.filter(
    (attachment) => !attachment.og_scrape_url
  )
  if (!quotedMessage.text && !quotedMessageAttachment) return null

  return (
    <div className={classes.quotedContainer} data-testid={"quoted-message-preview"}>
      <QuotedMessagePreviewHeader />
      <div className={classes.content}>
        <div className={classes.quotedMessageText}>
          <MessageText message={quotedMessage} />
        </div>

        {quotedMessageAttachmentWithoutLinks?.length ? (
          <div
            className={classes.quoteAttachments}
            data-testid={"quoted-message-attachments"}
          >
            <ChatChannelQuotedMessageAttachments
              quotedMessageAttachment={quotedMessageAttachmentWithoutLinks}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  quotedContainer: {
    "& .str-chat__message-attachment--image": {
      maxWidth: "300px",
      borderRadius: "16px",
    },
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  content: {
    display: "flex",
    width: "100%",
    gap: theme.spacing(2),
    flexDirection: "column",
    borderLeft:
      theme.palette.type === "dark"
        ? `2px solid ${theme.palette.groovy.neutral[400]}`
        : `2px solid ${theme.palette.groovy.grey[200]}`,
    paddingLeft: theme.spacing(1.5),
    position: "relative",
  },

  quoteAttachments: {
    display: "flex",
    gap: theme.spacing(1.5),
    overflowX: "auto",
    overflowY: "hidden",
    maxHeight: "276px",
  },
  quotedMessageText: {
    color:
      theme.palette.type === "dark"
        ? `${theme.palette.groovy.neutral[600]} !important`
        : theme.palette.groovy.grey[500],
    pointerEvents: "none",
    "& .str-chat__message-url-link": {
      color:
        theme.palette.type === "dark"
          ? `${theme.palette.groovy.neutral[600]} !important`
          : theme.palette.groovy.grey[500],
      transition: "none !important",
    },
    "& .str-chat__message-text-inner": {
      "& ul, & ol": {
        paddingLeft: theme.spacing(2),
      },
    },
  },
}))

export default ChatChannelQuotedMessagePreview
