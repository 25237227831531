import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import ChatChannelQuotedMessagePreview from "@components/chat/channel/detail/quoted-message/ChatChannelQuotedMessagePreview"
import { ChatChannelEditMessageInputQuery } from "@components/chat/channel/edit/__generated__/ChatChannelEditMessageInputQuery.graphql"
import { ChatChannelMessageInputActions } from "@components/chat/channel/message-input/ChatChannelMessageInputActions"
import { ChatChannelMessageMarkdownMenu } from "@components/chat/channel/message-input/ChatChannelMessageMarkdownMenu"
import MessageInputAutoComplete from "@components/chat/channel/message-input/MessageInputAutoComplete"
import { ChatChannelUploadsPreview } from "@components/chat/channel/uploads/ChatChannelUploadsPreview"
import { CHAT_CHANNEL_MAX_ROWS } from "@components/chat/channel/util/chatChannelConstants"
import { getUrlAttachments, processDiscoUrls } from "@components/chat/util/chatUtils"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import classNames from "classnames"
import { ClipboardEvent } from "react"
import { ImageDropzone } from "react-file-utils"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { useChannelStateContext, useMessageInputContext } from "stream-chat-react"
import { v4 as uuidv4 } from "uuid"

const ChatChannelEditMessageInput = () => {
  const { acceptedFiles, multipleUploads, quotedMessage } = useChannelStateContext()

  const {
    clearEditingState,
    cooldownRemaining,
    handleSubmit,
    isUploadEnabled,
    maxFilesLeft,
    uploadNewFiles,
    upsertAttachments,
    message,
    attachments,
    onPaste,
    text,
  } = useMessageInputContext()
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!
  const { organization } = useLazyLoadQuery<ChatChannelEditMessageInputQuery>(
    graphql`
      query ChatChannelEditMessageInputQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            products(type: "course") {
              edges {
                node {
                  id
                  name
                  slug
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    },
    {
      fetchPolicy: "store-and-network",
    }
  )

  return (
    <>
      <ChatChannelMessageMarkdownMenu />
      <div className={classes.container}>
        <div
          className={classNames(classes.root)}
          data-testid={"ChatChannelEditMessageInput.container"}
        >
          <ImageDropzone
            accept={acceptedFiles}
            disabled={!isUploadEnabled || maxFilesLeft === 0 || !!cooldownRemaining}
            handleFiles={uploadNewFiles}
            maxNumberOfFiles={maxFilesLeft}
            multiple={multipleUploads}
          >
            {/* Quote Preview */}
            {quotedMessage && (
              <ChatChannelQuotedMessagePreview quotedMessage={quotedMessage} />
            )}

            <div className={"str-chat__input-flat-wrapper"}>
              <div className={"str-chat__input-flat--textarea-wrapper"}>
                {/* Message */}
                <div className={classes.input}>
                  <MessageInputAutoComplete
                    onPaste={handlePaste}
                    rows={1}
                    maxRows={CHAT_CHANNEL_MAX_ROWS}
                    grow
                    placeholder={"Edit your message"}
                  />
                  {isUploadEnabled && (
                    <ChatChannelUploadsPreview
                      previewLinksToHide={
                        Array.isArray(message?.previewLinksToHide)
                          ? message?.previewLinksToHide
                          : undefined
                      }
                    />
                  )}
                  <div className={classes.inputMessage}>
                    <ChatChannelMessageInputActions />
                    <div className={classes.buttons}>
                      {/* Cancel */}
                      <DiscoButton
                        color={"grey"}
                        variant={"outlined"}
                        onClick={clearEditingState}
                      >
                        {"Cancel"}
                      </DiscoButton>

                      {/* Save */}
                      {!cooldownRemaining && (
                        <DiscoButton onClick={saveEditedMessage}>
                          {"Save Edits"}
                        </DiscoButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ImageDropzone>
        </div>
      </div>
    </>
  )

  async function saveEditedMessage(submitEvent: React.BaseSyntheticEvent) {
    if (text?.trim() === "" && attachments.length === 0) {
      displayErrorToast("Cannot save an empty message")
    } else {
      // process links in text
      const processedText = await processDiscoUrls(text, activeOrganization)
      handleSubmit(submitEvent, { text: processedText })
    }
  }

  function handlePaste(event: ClipboardEvent<HTMLTextAreaElement>) {
    onPaste(event)
    const pastedText = event.clipboardData.getData("Text")
    const products = Relay.connectionToArray(organization?.products)
    const urlAttachments = getUrlAttachments(pastedText, activeOrganization, products)

    if (urlAttachments && urlAttachments.length > 0) {
      const localUrlAttachments = urlAttachments.map((attachment) => ({
        ...attachment,
        localMetadata: {
          id: uuidv4(),
        },
      }))

      upsertAttachments(localUrlAttachments)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: `0 0 ${theme.measure.borderRadius.big}px ${theme.measure.borderRadius.big}px`,
  },
  root: {
    "& .rfu-dropzone": {
      "& .rfu-dropzone__notifier": {
        display: "none", // Hide by default
      },
      "&.rfu-dropzone--accept .rfu-dropzone__notifier": {
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        zIndex: theme.zIndex.raise3,
        alignItems: "center",
        justifyContent: "center",
        border: `2px dashed ${theme.palette.primary.main}`,
        borderRadius: theme.measure.borderRadius.medium,
        "& .rfu-dropzone__inner": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(2),
          "& svg": {
            color: theme.palette.primary.main,
          },
          "& p": {
            color: theme.palette.text.primary,
            margin: 0,
            ...theme.typography["body-md"],
          },
        },
      },
    },
    "&.str-chat__input-flat": {
      backgroundColor: theme.palette.background.paper,
      display: "list-item",
    },
    "&.str-chat__input-flat-wrapper": {
      display: "block",
      width: "100%",
    },
    "& .str-chat__input-flat--textarea-wrapper": {
      maxWidth: "100%",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
      },
    },
    "& .str-chat__textarea": {
      display: "flex",
      alignItems: "center",
      width: "100%",

      "& > textarea": {
        width: "100%",
        background: "transparent !important",
        padding: theme.spacing(1, 2),
        borderRadius: theme.measure.borderRadius.medium,
        color: theme.palette.text.primary,
        border: "none !important",
        ...theme.typography["body-sm"],

        "&:focus": {
          border: "none !important",
          boxShadow: "none !important",
          background: "transparent !important",
          ...theme.typography["body-sm"],
          color: theme.palette.text.secondary,
        },

        "&::placeholder": {
          ...theme.typography["body-sm"],
          color: theme.palette.groovy.grey[300],
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxHeight: "40px",
        },
      },
    },
  },
  input: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    background: theme.palette.background.paper,
    borderRadius: `0 0 ${theme.measure.borderRadius.big}px ${theme.measure.borderRadius.big}px`,
  },
  inputMessage: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    background: "transparent",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(0.5),
  },
}))

export default ChatChannelEditMessageInput
