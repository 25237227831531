import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import {
  formatBytesToReadableString,
  getFileTypeLabelFromFileName,
} from "@utils/file/fileUtils"

export declare type Props = {
  name?: string
  url?: string
  size?: number
}

export const CustomChatChannelFileAttachment = ({ name, url, size }: Props) => {
  const classes = useStyles()

  return (
    <>
      {name && (
        <div className={classes.fileType}>
          <DiscoText variant={"body-md-600-uppercase"} color={"text.primary"}>
            {getFileTypeLabelFromFileName(name)}
          </DiscoText>
        </div>
      )}

      {url && (
        <a href={url} download className={classes.text}>
          <DiscoText variant={"body-md-600"} truncateText={1}>
            {name || "File"}
          </DiscoText>
          {size && (
            <DiscoText variant={"body-xs-500"} color={"text.secondary"}>
              {formatBytesToReadableString(size, 1)}
            </DiscoText>
          )}
        </a>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  fileType: {
    height: "48px",
    width: "48px",
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },
  text: {
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
  },
}))
